import React, { useState } from 'react';

import withLayout from '../layout';
import { findGetParameter } from '../util/uri';
import { Input } from '../components/Form/Input';
import { apiResetPasswordConfirm } from '../util/api';
import { isEqual } from '../util/validation';
import { Link } from 'gatsby';

const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [sent, setSent] = useState(false);

  const onChange = event => {
    setSent(false);
    switch (event.target.name) {
      case 'password':
        if (!isEqual(confirmPassword, event.target.value)) {
          setHasError(true);
        } else {
          setHasError(false);
        }
        setPassword(event.target.value);
        break;
      case 'confirmPassword':
        if (!isEqual(password, event.target.value)) {
          setHasError(true);
        } else {
          setHasError(false);
        }
        setConfirmPassword(event.target.value);
        break;
      default:
        console.log(event.target.name);
    }
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (!Boolean(hasError)) {
      const submitData = {
        password: password,
        token: findGetParameter('token'),
      };

      const result = await apiResetPasswordConfirm(submitData);
      setSent(true);

      if (!Boolean(result)) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }
  };

  return (
    <div
      className="container"
      style={{
        margin: `0 auto`,
      }}
    >
      <h1>Passwort Reset</h1>
      {!Boolean(sent) || !(Boolean(sent) && !Boolean(hasError)) ? (
        <>
          <p>
            Sie haben einen Passwort Reset angefordert, bitte geben Sie in unten stehendem Formular
            ihr neues Passwort ein.
          </p>
          <form onSubmit={onSubmit}>
            <Input
              type="password"
              name="password"
              handleChange={onChange}
              value={password}
              label="Passwort"
              required={true}
              hasError={hasError}
            />
            <Input
              type="password"
              name="confirmPassword"
              handleChange={onChange}
              value={confirmPassword}
              label="Passwort wiederholen"
              required={true}
              hasError={hasError}
              hint="Passwörter stimmen nicht überein"
            />
            <button className="btn" type="submit">
              Passwort zurücksetzen
            </button>
          </form>
          {Boolean(sent) && (
            <>
              <br />
              <div className="toast toast-error">
                Ihr Passwort konnte nicht geändert werden, bitte überprüfen Sie Ihre Mail und/oder
                beantragen Sie einen neuen Link.
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <p>Ihr Passwort wurde erfolgreich zurückgesetzt</p>
          <Link to="/login">Jetzt einloggen</Link>
        </>
      )}
    </div>
  );
};

const customProps = {
  localeKey: 'password-reset', // same as file name in src/i18n/translations/your-lang/index.js
  withAuth: false,
};

export default withLayout(customProps)(PasswordReset);
